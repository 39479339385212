import "./App.css";
import GridTable from "@nadavshaar/react-grid-table";
import getColumn from "./getColumn";
import { useEffect, useState } from "react";
import { user } from "./axiosClient";
import { web } from "./web";
const combinedList = [...web.gang,...web.gang2, ...web.nganh2];
function App() {
    const [search, setSearch] = useState([]);
    const [userList, setUserList] = useState([]);
    const [label, setLabel] = useState("all");
    const [selectList, setSelectList] = useState([]);
    const [isReset, setIsReset] = useState(false);
    useEffect(() => {
        user.getall().then((data) => {
            setSearch(data?.Data?.players);
            setUserList(data?.Data?.players);
        });

        setSelectList(combinedList);
    }, [isReset]);
    const handleChangeOne = (e) => {
        setLabel(e.target.value);
    };
    const handleChangeTwo = (e) => {
        if (label === "all") {
            const newValue1 = search.filter((item) => {
                return item?.name.toLowerCase().indexOf(e.target.value.toLowerCase().trim()) > -1;
            });
            const newValue2 = search.filter((item) => {
                return (
                    item?.id.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) >
                    -1
                );
            });
            setUserList([...newValue1, ...newValue2]);
        }
        if (label === "id") {
            const newValue = search.filter((item) => {
                return (
                    item?.id.toString().toLowerCase().indexOf(e.target.value) >
                    -1
                );
            });
            setUserList(newValue);
        }
        if (label === "name") {
            console.log(123);
            const newValue = search.filter((item) => {
                return item?.name.toLowerCase().indexOf(e.target.value.toLowerCase().trim()) > -1;
            });
            setUserList(newValue);
        }
    };

    const handleChangeThree = (e) => {
        if (e.target.value === "all") {
            setIsReset(!isReset)
        }
        const valueSelect = selectList.filter((item) => {
            return item.label === e.target.value;
        });

        let searchValueAfter = [];
        if (valueSelect[0]?.nametag.length > 0) {
            valueSelect[0]?.nametag.forEach((element) => {
                const newValue = search.filter((item) => {
                    return (
                        item?.name.trim().toLowerCase().indexOf(element.toString()) ===
                        0
                    );
                });
                searchValueAfter = [...searchValueAfter, ...newValue];
            });
        }
        if (valueSelect[0]?.nametag1.length > 0) {
            valueSelect[0]?.nametag1.forEach((element) => {
                const newValue = search.filter((item) => {
                    return (
                        item?.name.trim().toLowerCase().indexOf(element.toString()) ===
                        0
                    );
                });
                searchValueAfter = [...searchValueAfter, ...newValue];
            });
        }
        setUserList(searchValueAfter);
    };
    return (
        <div className="App">
            <div className="container">
                <h1 className="heading">Thống kê người chơi </h1>
                <div className="search-container">
                    <select
                        name=""
                        id=""
                        className="select"
                        onChange={handleChangeOne}
                    >
                        <option value="all">all</option>
                        <option value="id">id</option>
                        <option value="name">name</option>
                    </select>

                    <input
                        type="text"
                        className="input"
                        placeholder="search something..."
                        onChange={handleChangeTwo}
                    />
                    <select
                        name=""
                        className="select"
                        onChange={handleChangeThree}
                    >
                        <option value="all">all</option>
                        {selectList.map((item, index) => (
                            <option key={index} value={item.label}>
                                {item.label}
                            </option>
                        ))}
                    </select>
                </div>
                <GridTable
                    showColumnVisibilityManager={false}
                    showSearch={false}
                    isPaginated={false}
                    showRowsInformation={false}
                    columns={getColumn()}
                    pageSize={100}
                    rows={userList}
                />
            </div>
        </div>
    );
}

export default App;
