


const getColumn = () => {
    return [
        {
            id: "1",
            label: "STT",
            cellRenderer:  ({ tableManager, value, data, column, colIndex, rowIndex }) => ( rowIndex ),
            width: "120px"

        },
        {
            id: "2",
            field: "id",
            label: "ID",
            width: "120px"
          

        },
        {
            id: "3",
            field: "name",
            label: "Name",
            width: "360px"

        },
       
       
    ];
};

export default getColumn;
