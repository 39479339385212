const { default: axios } = require("axios");

const axiosClient = axios.create({
    baseURL: "https://servers-frontend.fivem.net",
    headers: {
        "content-type": "application/json",
    },
});
// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    }
);
export default axiosClient

export const user= {
    getall(){
        const url = "api/servers/single/86zggv"
        return axiosClient.get(url)
    }
}