export const web = {
    notnametag: {
        NOTNAMETAG: ["mjxudzye" , "mj | tt | hoanggiau"],
    },
    gang: [
        {
            "label": "TiTans",
            "nametag": [
                "titans"
            ],
            "label1": "TTS",
            "nametag1": [
                "tts"
            ]
        },
        {
            "label": "Last Legend",
            "nametag": [
                "last legend"
            ],
            "label1": "LG",
            "nametag1": [
                "lg"
            ]
        },
        {
            "label": "Bạch Ma",
            "nametag": [
                "bạch ma"
            ],
            "label1": "BM",
            "nametag1": [
                "bm"
            ]
        },
        {
            "label": "Chaebol",
            "nametag": [
                "chaebol"
            ],
            "label1": "CB",
            "nametag1": [
                "cb"
            ]
        },
        {
            "label": "Cấm Thuật",
            "nametag": [
                "cấm thuật"
            ],
            "label1": "CT",
            "nametag1": [
                "ct"
            ]
        }
    ],
    gang2: [
        {
            "label": "Paradise",
            "nametag": [
                "paradise"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Lil",
            "nametag": [
                "lil |"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "LOR's Farmer",
            "nametag": [
                "lor's farmer"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Mutiny",
            "nametag": [
                "mutiny"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Panther",
            "nametag": [
                "panther"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Brotherhood",
            "nametag": [
                "brotherhood"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Dinosaur",
            "nametag": [
                "dinosaur"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "MFA",
            "nametag": [
                "mfa"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "SEN",
            "nametag": [
                "sen"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Black Hole",
            "nametag": [
                "black hole"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Family",
            "nametag": [
                "family"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "ReBorn",
            "nametag": [
                "reborn"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Band4Band",
            "nametag": [
                "band4band"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Zenith",
            "nametag": [
                "zenith"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "8xbet",
            "nametag": [
                "8xbet"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Grind",
            "nametag": [
                "grind"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "Ocean",
            "nametag": [
                "ocean"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "SEAL",
            "nametag": [
                "seal"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "ViệtNamZ",
            "nametag": [
                "việtnamz"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        }
    ],
    nganh2: [
        {
            "label": "Công An",
            "nametag": [
                "ca |",
                "gđcs",
                "qlsw",
                "gdcs",
                "pgđcs",
                "pgdcs",
                "thanh tra",
                "pgđca",
                "pgdca",
                "gđca",
                "gdca",
                "qlca",
                "s.w.a.t",
                "swat",
                "quân y",
                "quân y|",
                "quan y"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        },
        {
            "label": "SOS",
            "nametag": [
                "qlss",
                "qlsos",
                "gđsos",
                "pgđsos",
                "gdbsos",
                "pgdsos",
                "gđss",
                "pgđss",
                "gdbss",
                "pgdss",
                "sos"
            ],
            "label1": null,
            "nametag1": [
                ""
            ]
        }
    ]
}

